import {Chart, ChartData, PluginOptionsByType, ScaleOptionsByType} from 'chart.js';
import 'chartjs-adapter-moment';
import React from 'react';

import {
  formatUnits,
  getGraphTimeRangeWithBuffer,
  useLineChart,
} from 'app/components/AnalyzePolygonChart/utils';
import {DataLayerInfo} from 'app/utils/layers';
import {getLayer} from 'app/utils/layerUtils';

import {MultifeatureAnalysisConfig} from '../hooks/useAggregatedFeatureStats';
import {MultiFeatureScatterDataPoint} from '../utils/chartUtils';

const SMALL_CHART_HEIGHT = 200;
const LARGE_CHART_HEIGHT = 300;

/**
 * Component to render a line chart with an arbitrary number of datasets.
 */
const MultiFeatureAnlaysisLineChart: React.FunctionComponent<{
  datasets: ChartData<'line', MultiFeatureScatterDataPoint[]>['datasets'];
  hideLegend?: boolean;
  widgetConfig: MultifeatureAnalysisConfig;
  isExpanded: boolean;
}> = ({datasets, hideLegend = false, widgetConfig: {timeAggregation, layerKey}, isExpanded}) => {
  const layerInfo = getLayer(layerKey) as DataLayerInfo;
  const {dataRange} = layerInfo;
  /** Calculate x-axis values. */
  const {dates, timeRangeLimits} = React.useMemo(() => {
    const dates = datasets.flatMap((dataset) => dataset.data.map((point) => point.date));
    const timeRangeLimits = getGraphTimeRangeWithBuffer(dates);
    return {dates, timeRangeLimits};
  }, [datasets]);

  const legendPluginOptions = React.useMemo<PluginOptionsByType<'line'>['legend']>(
    () =>
      ({
        display: !hideLegend,
        labels: {
          generateLabels: (chart) => {
            return [...Chart.defaults.plugins.legend.labels.generateLabels(chart)];
          },
        },
      }) as PluginOptionsByType<'line'>['legend'],
    [hideLegend]
  );

  /** Allow chart to be optionally panned and zoomed. */
  const zoomPluginOptions = React.useMemo<PluginOptionsByType<'line'>['zoom']>(
    () => ({
      limits: {
        x: {
          min: timeRangeLimits[0].getTime(),
          max: timeRangeLimits[1].getTime(),
          minRange: 15 * 24 * 60 * 60 * 1000,
        },
      },
      pan: {
        mode: 'x',
        enabled: true,
      },
      zoom: {
        mode: 'x',
        pinch: {
          enabled: true,
        },
        wheel: {
          enabled: true,
        },
      },
    }),
    [timeRangeLimits]
  );

  /** Configure time scale (i.e., x-axis) settings. */
  const xScaleOptions = React.useMemo<ScaleOptionsByType<'time'>>(() => {
    return {
      type: 'time',
      min: timeRangeLimits[0],
      max: timeRangeLimits[1],
      time: {
        unit: timeAggregation === 'yearly' ? 'year' : 'month',
        displayFormats: {
          month: 'MMM YYYY',
          year: 'YYYY',
        },
        tooltipFormat: timeAggregation === 'yearly' ? 'YYYY' : 'MMM YYYY',
      },
      ticks: {
        autoSkip: true,
        autoSkipPadding: 10,
        minRotation: 0,
        maxRotation: 0,
      },
      grid: {
        borderDash: [1, 1],
      },
    } as unknown as ScaleOptionsByType<'time'>;
  }, [timeRangeLimits, timeAggregation]);

  /** Configure linear scale (i.e., y-axis) settings. */
  const yScaleOptions = React.useMemo<ScaleOptionsByType<'linear'>>(
    () =>
      ({
        type: 'linear',
        min: dataRange[0],
        max: dataRange[1] || 1, // If max is 0, set it to 1 to avoid breaking y-axis labels
        ticks: {
          precision: 1,
          maxTicksLimit: 2,
          callback: function (value, _index, _ticks) {
            return formatUnits(value, (layerInfo as DataLayerInfo).dataUnit);
          },
        },
        grid: {
          borderDash: [1, 1],
          drawBorder: false,
        },
      }) as ScaleOptionsByType<'linear'>,
    [dataRange, layerInfo]
  );

  // TODO: set to false, we really don't need this plugin at all. Keeping in for now in case we need it.
  const titlePluginOptions = React.useMemo<Partial<PluginOptionsByType<'line'>['title']>>(
    () => ({
      display: false,
      text: layerInfo.display,
      font: {weight: 'normal'} as PluginOptionsByType<'line'>['title']['font'],
    }),
    [layerInfo.display]
  );

  // const {renderAxisTooltip, hoverYAxisPlugin} = useYAxisTooltip(graph);

  const {canvasRefCallback} = useLineChart({
    datasets,
    labels: dates,
    annotationPluginOptions: {
      annotations: [],
    },
    legendPluginOptions,
    zoomPluginOptions,
    titlePluginOptions,
    xScaleOptions,
    yScaleOptions,
    plugins: [],
    isExpanded,
  });

  return (
    <React.Fragment>
      <div style={{height: isExpanded ? LARGE_CHART_HEIGHT : SMALL_CHART_HEIGHT}}>
        <canvas ref={canvasRefCallback} />
      </div>
    </React.Fragment>
  );
};

export default MultiFeatureAnlaysisLineChart;
