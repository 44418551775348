import * as B from '@blueprintjs/core';
import React from 'react';

import {DashboardWidget} from 'app/components/Dashboard/DashboardWidget';
import {
  MultifeatureAnalysisConfig,
  useMultiFeatureAnalysisStats,
} from 'app/components/Dashboard/hooks/useAggregatedFeatureStats';
import {
  calculateTrendLine,
  getAllSortedTimestamps,
  makeMultiFeatureDatasets,
  makeTrendLineChartDatasets,
} from 'app/components/Dashboard/utils/chartUtils';
import {DataLayerInfo} from 'app/utils/layers';
import {getLayer} from 'app/utils/layerUtils';

import * as cs from './MultiFeatureAnalysis.styl';
import MultiFeatureAnlaysisLineChart from './MultiFeatureAnalysisChart';
import MultiFeatureAnlaysisTable from './MultiFeatureAnalysisTable';

export const MultiFeatureAnalysisWidget: React.FC<{
  widgetConfig: MultifeatureAnalysisConfig;
  featureNames: {[featureId: string]: string};
}> = ({widgetConfig, featureNames}) => {
  const {featureIds, layerKey, timeAggregation} = widgetConfig;

  //TODO: This is where we will control the current set of currently displayed datasets.
  //We will need to pass the setter logic here to both the chart and the table, so that
  //we can affect the chart by interacting with the table (and vice versa?)
  const [showAggregateTrend, setShowAggregateTrend] = React.useState(true);
  const [displayFeatureIds, setDisplayFeatureIds] = React.useState<string[]>([]);
  const [showFeatureTable, setShowFeatureTable] = React.useState(false);

  const {data, isLoading, error, isFetching} = useMultiFeatureAnalysisStats(widgetConfig);

  const [multiFeatureDatasets, setMultiFeatureDatasets] = React.useState(
    data ? makeMultiFeatureDatasets(data, displayFeatureIds, timeAggregation, featureNames) : null
  );

  React.useEffect(() => {
    if (displayFeatureIds && data) {
      const newDatasets = makeMultiFeatureDatasets(
        data,
        displayFeatureIds,
        timeAggregation,
        featureNames
      );
      setMultiFeatureDatasets(newDatasets);
    }
  }, [data, displayFeatureIds, featureNames, timeAggregation]);

  const layer = getLayer(layerKey);
  const allDates = getAllSortedTimestamps(data);

  // TODO: the type of aggregation we do here will depend on the dataset. for category
  // we'll want to sum area of each category, rather than means.
  const trendLine = React.useMemo(() => calculateTrendLine(data), [data]);
  const trendLineDatasets = React.useMemo(
    () =>
      makeTrendLineChartDatasets(
        trendLine,
        layer as DataLayerInfo,
        showFeatureTable,
        timeAggregation
      ),
    [trendLine, layer, showFeatureTable, timeAggregation]
  );

  return (
    <DashboardWidget
      title={widgetConfig.name}
      size={showFeatureTable ? 'large' : 'small'}
      controls={
        <div>
          <B.Tooltip content={showFeatureTable ? 'Hide details' : 'Show details'}>
            <B.AnchorButton
              icon={showFeatureTable ? 'minimize' : 'maximize'}
              minimal
              small
              onClick={() => {
                setShowFeatureTable((prev) => !prev);
                setDisplayFeatureIds(showFeatureTable ? [] : featureIds.slice(0, 4));
              }}
            />
          </B.Tooltip>
          {/* TODO - placeholder for menu of actions (download, etc) */}
          <B.AnchorButton icon="more" minimal small />
        </div>
      }
    >
      {/* Basic loading/error display */}
      {isLoading && <div>Loading...</div>}
      {isFetching && <div>Fetching...</div>}
      {error && <div>Error: {error.message}</div>}

      <div>
        <p>
          <span className={cs.widgetLabel}>Description:</span> {widgetConfig.description}
        </p>
        {/* Summary of data displayed in the chart */}
        <p>
          <span className={cs.widgetLabel}>Chart: </span>{' '}
          {`${layer.display} | ${widgetConfig.featureIds.length} properties`}
        </p>
      </div>

      {multiFeatureDatasets && featureIds && (
        <MultiFeatureAnlaysisLineChart
          widgetConfig={widgetConfig}
          datasets={
            !showFeatureTable
              ? trendLineDatasets
              : showAggregateTrend
                ? [...trendLineDatasets, ...multiFeatureDatasets]
                : multiFeatureDatasets
          }
          hideLegend
          isExpanded={showFeatureTable}
        />
      )}

      {featureIds && showFeatureTable && data && (
        <MultiFeatureAnlaysisTable
          displayFeatureIds={displayFeatureIds}
          allFeatureIds={featureIds}
          setDisplayFeatureIds={setDisplayFeatureIds}
          featureStats={data}
          featureNames={featureNames}
          allDates={allDates}
          setShowAggregateTrend={setShowAggregateTrend}
          showAggregateTrend={showAggregateTrend}
          widgetConfig={widgetConfig}
        />
      )}
    </DashboardWidget>
  );
};
